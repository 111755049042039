import { useContentReportMutation } from 'gql'
import { I18nHtml, Routes, t } from 'shared'
import { Radio, RadioGroup, Label, Field, Button, Input, Textarea } from '@headlessui/react'
import { SubmitHandler, useForm, useController, Control } from 'react-hook-form'

type FormValues = {
  name: string
  email: string
  type: string
  message: string
}

const DenunciationTypeSelection = ({ control }: { control: Control<FormValues> }) => {
  const {
    field: { value, onChange },
  } = useController({ control, name: 'type', rules: { required: true } })

  return (
    <RadioGroup className="flex flex-col gap-2" value={value} onChange={onChange}>
      {['fraud', 'improper', 'copyright'].map((type, i) => (
        <Field key={i} className="flex flex-col border-b pb-2">
          <div className="flex gap-2">
            <Radio
              value={type}
              className="group flex size-5 items-center justify-center rounded-full border bg-white data-[checked]:bg-blue-400"
            >
              <span className="invisible size-2 rounded-full bg-white group-data-[checked]:visible" />
            </Radio>
            <Label className="font-bold">{t(`report.${type}.label`)}</Label>
          </div>
          <I18nHtml
            as="p"
            i18nKey={`report.${type}.explanation_html`}
            className="prose max-w-none prose-a:text-me-purple-600 prose-a:no-underline hover:prose-a:underline"
            options={{
              additionalTermsUrl: `${Routes.termsAdditionalPath()}#3-zulässige-zwecke`,
            }}
          />
        </Field>
      ))}
    </RadioGroup>
  )
}

export const ReportForm = ({ onSuccess }: { onSuccess: () => void }) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>()

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    mutate({ variables: data })
  }

  const [mutate, { data }] = useContentReportMutation()

  if (data?.contentReport) {
    onSuccess()
  }

  return (
    <form className="px-6 flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
      <DenunciationTypeSelection control={control} />
      {errors.type && <p className="text-red-500">{t('error.type.blank')}</p>}
      <div className="flex gap-4 flex-col md:flex-row">
        <Field className="flex flex-col flex-grow">
          <Label className="font-bold">{t('report.name_label')}</Label>
          <Input
            type="text"
            {...register('name', { required: true })}
            invalid={!!errors.name}
            className="data-[invalid]:bg-red-50 data-[invalid]:border-red-500 rounded border-me-gray-400"
          />
          {errors.name && <p className="text-red-500">{t('error.name.blank')}</p>}
        </Field>
        <Field className="flex flex-col flex-grow">
          <Label className="font-bold">{t('report.email_label')}</Label>
          <Input
            type="email"
            {...register('email', { required: true })}
            invalid={!!errors.email}
            className="data-[invalid]:bg-red-50 data-[invalid]:border-red-500 rounded border-me-gray-400"
          />
          {errors.email && <p className="text-red-500">{t('error.email.blank')}</p>}
        </Field>
      </div>
      <Field className="flex flex-col flex-grow">
        <Label className="font-bold">{t('report.message_label')}</Label>
        <Textarea
          {...register('message', { required: true })}
          invalid={!!errors.message}
          className="data-[invalid]:bg-red-50 data-[invalid]:border-red-500 rounded border-me-gray-400"
        ></Textarea>
        {errors.message && <p className="text-red-500">{t('error.message.blank')}</p>}
      </Field>
      <p>{t('report.info')}</p>
      <div>
        <Button type="submit" className="btn-primary text-lg [&]:px-8">
          {t('verbs.send')}
        </Button>
      </div>
    </form>
  )
}
