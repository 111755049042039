import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { useState } from 'react'
import { t, useBooleanState } from 'shared'
import { ReportForm } from './report_form'

export const ReportModal = ({ label }: { label: string }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [sent, setSent] = useBooleanState(false)

  const handleClick = () => {
    setIsOpen(true)
  }

  return (
    <>
      <button onClick={handleClick} className="text-me-purple-600 hover:underline">
        {label}
      </button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50">
        <div className="fixed inset-0 bg-black/80" aria-hidden="true" />
        <div className="fixed inset-0 w-screen overflow-y-auto p-4">
          <div className="flex min-h-full items-center justify-center">
            <DialogPanel className="min-w-96 max-w-5xl space-y-4 border bg-white py-5 rounded-md">
              <DialogTitle className="font-bold text-2xl px-6 pb-5 relative pr-8">
                <span>{t('report.header')}</span>
                <button className="absolute top-0 right-0 px-4" onClick={() => setIsOpen(false)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                  </svg>
                </button>
              </DialogTitle>
              {sent ? <div>{t('report.reported_message')}</div> : <ReportForm onSuccess={setSent} />}
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  )
}
