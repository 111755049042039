import { ApolloProvider } from '@apollo/client'
import { createRoot } from 'react-dom/client'
import { client } from 'shared'
import { ReportModal } from 'islands/report_modal'

function renderReportModal() {
  const domNode = document.getElementById('report-modal')

  if (domNode) {
    const root = createRoot(domNode)

    root.render(
      <ApolloProvider client={client}>
        <ReportModal label={domNode.innerText} />
      </ApolloProvider>
    )
  }
}

document.addEventListener('htmx:afterSettle', renderReportModal)
document.addEventListener('DOMContentLoaded', renderReportModal)
window.addEventListener('htmx:historyRestore', renderReportModal)
